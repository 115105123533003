import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../../helpers/Config';
import SiteBreadcrumb from '../../components/layout/SiteBreadcrumb';
import { Media, Row, Col, Container } from "reactstrap";
import aboutImg from '../../../resources/custom/images/about-us.jpg';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';


// import CustomerTestimonial from '../../components/shared/CustomerTestimonial';

const About = () => {
  const [siteTitle, setSiteTitle] = useState(Config['SITE_TTILE']);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);



    useEffect(() => {
        // declare the data fetching function
        const dataOperationFunc = async () => {

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["About"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                await setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        dataOperationFunc().catch(console.error);
    }, [])
  return (
    <>


      <Helmet>
        <title>{siteTitle} - About Us</title>
        <meta name="description" content={siteTitle + " - About us page"}  />
        <meta name="keywords" content="About us"></meta>
      </Helmet>

      <SiteBreadcrumb title="About-Us" parent="Home"/>

      <section className="about-page section-big-py-space">
        <div className="custom-container">
          <Row>
            <Col lg="6">
              <div className="banner-section">
                <Media src={aboutImg} className="img-fluid w-100" alt="" />
              </div>
            </Col>
            <Col lg="6">
                          <h4>

                              {LocalizationLabelsArray.length > 0 ?
                                  replaceLoclizationLabel(LocalizationLabelsArray, "About", "lbl_about_Store")
                                  :
                                  "About"
                              }                             </h4>
              <p className="mb-2">
              
                              {LocalizationLabelsArray.length > 0 ?
                                  replaceLoclizationLabel(LocalizationLabelsArray, "About", "lbl_about")
                                  :
                                  "About"
                              }
              </p>
              
            
           
            </Col>
          </Row>
        </div>
      </section>

      {/* <CustomerTestimonial /> */}

    </>
  );

}

export default About;
