import React from "react";
import { Container, Row, Col } from "reactstrap";

const DiscountBannerOmg = () => {
  return (
    <section className="discount-banner">
      <Container>
        <Row>
       
        </Row>
      </Container>
    </section>
  );
};

export default DiscountBannerOmg;
